import React from 'common/react-vendor';
import moment from 'moment';

enum FundamentalType {
	alpha,
	boolean,
	numeric,
	percentage,
	currency,
	email,
	phone,
	uri,
	year,
	enum,
	date,
}

type ParamType = string | number | null;
type ResultType = string | number | boolean | JSX.Element | null;

type IFundamentalTypeFormatters<
	P extends ParamType[],
	R extends ResultType[]
> = {
	[i in FundamentalType]: (value: P[i]) => R[i];
};

type ParamTypeList = [
	string,
	number,
	number,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string | null
];

type ResultTypeList = [
	string,
	string,
	number | string,
	string,
	string,
	string,
	JSX.Element,
	JSX.Element,
	string,
	string,
	string | null
];

/* eslint-disable react/display-name */
const fundamentalTypeFormatters: IFundamentalTypeFormatters<
	ParamTypeList,
	ResultTypeList
> = {
	[FundamentalType.alpha]: (value: string) => value,
	[FundamentalType.boolean]: (value: number) => {
		if (value === 2) {
			return 'False';
		}
		return value ? 'True' : 'False';
	},
	[FundamentalType.numeric]: (value: number) => {
		// only cast to number if it is a valid number, otherwise return original value
		if (value === null) {
			return '';
		}
		return Number.isNaN(Number(value)) ? value : Number(value);
	},
	[FundamentalType.percentage]: (value: string) => `${Number(value) * 100}%`,
	[FundamentalType.currency]: (value: string) => {
		if (value === null) {
			return `$${0}`;
		}
		return `$${value}`;
	},
	[FundamentalType.email]: (value: string) => {
		if (value === null) return '';
		return value.toLowerCase();
	},
	[FundamentalType.phone]: (value: string) => (
		<a
			target='_blank'
			rel='noopener noreferrer'
			href={`tel:${value}`}
			onClick={(e) => e.stopPropagation()}>
			{value}
		</a>
	),
	[FundamentalType.uri]: (value: string) => (
		<a
			target='_blank'
			rel='noopener noreferrer'
			href={value}
			onClick={(e) => e.stopPropagation()}>
			{value}
		</a>
	),
	[FundamentalType.year]: (value: string) => value,
	[FundamentalType.enum]: (value: string) => value, // TODO: how to format enum for display?
	[FundamentalType.date]: (value: string | null) =>
		value ? moment(value).format('MMM D, YYYY') : value,
};
/* eslint-enable react/display-name */

export const formatByFundamentalType = (
	value: ParamType,
	fundamentalType: FundamentalType
): ResultType => {
	const formatter = fundamentalTypeFormatters[fundamentalType] as (
		value: ParamTypeList[typeof fundamentalType]
	) => ResultTypeList[typeof fundamentalType];
	return formatter(value);
};

export {FundamentalType, fundamentalTypeFormatters};
