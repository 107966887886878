import LocalStorageUtility from 'common/widgets/utilities/local-storage.utility';
import {
	getDateNinetyDaysAway,
	getDays,
} from 'common/app/utilities/TimestampIntervalUtility';
import {getTenantHistory} from 'common/app/services/LoginService';

export const login = {
	username: '',
	expires: null,
	alreadyExpired: false,
	expireDays: 0,
	tenant: '',
	history: [],
};

export const set = (LoginDocument, ClientSession) => {
	login.username = LoginDocument.UserName;
	login.expires = getDateNinetyDaysAway(LoginDocument.PasswordLastModified);
	login.alreadyExpired = getDays(LoginDocument.PasswordLastModified) >= 90;
	login.expireDays = Math.abs(getDays(LoginDocument.PasswordLastModified) - 90);

	if (ClientSession && ClientSession.Tenant) {
		login.tenant = ClientSession.Tenant.DisplayName;
	}
	setHistory();
};

export const setHistory = () => {
	login.history = getTenantHistory();
};

function isSafeUrl(url) {
	try {
		const parsed = new URL(url, window.location.origin);
		return parsed.origin === window.location.origin;
	} catch (error) {
		throw new error('Error while validating URL safety:', error);
	}
}

export const redirectToApp = (tenant) => {
	const referringUrl = sessionStorage.getItem('referringUrl');
	sessionStorage.removeItem('referringUrl');
	// Don't redirect to signout to avoid infinite signout loop
	if (referringUrl && !referringUrl.includes('/signout')) {
		if (isSafeUrl(referringUrl)) {
			window.location.href = referringUrl;
			return;
		}
	}
	redirectToLP(tenant);
};

export const redirectToLP = (Tenant) => {
	const pathMap = {
		'4.0': '/atlas/',
		'3.0': '/lpi/',
		'2.0': '/lp2/',
	};

	if (!Tenant) {
		const ClientSession = LocalStorageUtility.getClientSession();
		// eslint-disable-next-line no-param-reassign
		Tenant = ClientSession ? ClientSession.Tenant : null;
	}

	const UIVersion = Tenant.UIVersion || '2.0';

	return window.open(pathMap[UIVersion] || '/lp2', '_self');
};
